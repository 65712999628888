import React from "react";
import { graphql } from "gatsby";
import BlockContent from "../components/block-content";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import styled from "styled-components";
import { mapEdgesToNodes } from "../lib/helpers";

import { responsiveTitle1 } from "../components/typography.module.css";
import Book from "../components/globals/Book";

export const query = graphql`
	query AboutPageQuery {
		page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
			id
			title
			_rawBody
		}
		people: allSanityPerson {
			edges {
				node {
					id
					image {
						asset {
							_id
						}
					}
					name
					_rawBio
				}
			}
		}
	}
`;

const Column = styled.div`
	padding-left: 70px;
	padding-right: 90px;
`

const AboutPage = (props) => {
	const { data, errors } = props;

	if (errors) {
		return (
			<Layout>
				<GraphQLErrorList errors={errors} />
			</Layout>
		);
	}

	const page = data && data.page;

	if (!page) {
		throw new Error(
			'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
		);
	}
	console.log(mapEdgesToNodes(data.people));
	return (
		<Layout>
			<SEO title={page.title} />

			<Book>
				<Column>
					<h1 className={responsiveTitle1}>{page.title}</h1>
					<BlockContent blocks={page._rawBody || []} />
				</Column>
			</Book>
		</Layout>
	);
};

export default AboutPage;
